import {
  Component, Input, QueryList, ViewChildren, EventEmitter, Output, OnChanges, SimpleChanges, OnInit,
} from '@angular/core';
import { moveItemInArray, CdkDropList } from '@angular/cdk/drag-drop';
import {
  UIConstants,
  ThumbnailSizes,
  ListingPhoto,
  PhotoDownloadService,
  MarketingOrder,
  ProductCode,
  PhotoArrayType,
  PromptDialogService,
  FeatureFlags,
  LaunchDarklyService,
  ToasterService,
  UpdateCurrentOrderState,
  AppService,
  MarketingOrderService,
} from '@lc/core';
import { PhotoGalleryDialogService } from '@lc/shared-components-ui';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { PhotoComponent } from './photo/photo.component';
import { PhotoViewModel } from '../photo-view-model';
import { OrderPhotoService } from '../../../../../order-management/src/lib/services/order-photo.service';

@Component({
  selector: 'lc-photo-list',
  templateUrl: './photo-list.component.html',
  styleUrls: ['./photo-list.component.scss'],
  standalone: false,
})
export class PhotoListComponent implements OnChanges, OnInit {
  isDragging: boolean = false;
  public anySelected: boolean;
  public isAllSelected: boolean;
  public someSelected: boolean = false;
  public showFavorites = false;
  public numberOfFavorites = 0;
  public isHiResDwldWaiting: boolean = false;
  public isLowResDwldWaiting: boolean = false;
  public imagesProcessing: boolean = false;
  public useFourWideGrid = true;
  public isManagePhoto = true;
  public gridClassName = 'product-grid';

  @Input() viewModels: PhotoViewModel[];
  @Input() productCode: string;
  @Input() photoType: string;
  @Input() additionalDrops: CdkDropList[];
  @Input() verticalScrollbar = true;
  @Input() favoritesEnabled: boolean = true;
  @Input() dragDropEnabled: boolean = true;
  @Input() deleteEnabled: boolean = true;
  @Input() downloadEnabled: boolean = true;
  @Input() selectEnabled: boolean = true;
  @Input() numberOrderingEnabled: boolean = true;
  @Input() showMenu: boolean = true;
  @Input() aiEnabled: boolean = false;

  // Flag to use to indicate that there is a dependent of the cdk
  // in the DOM. TODO: Swap CDK with SortableJS and remove this flag.
  @Input() usingCdk: boolean; /** Flag to use to indicate that there is a dependent of the cdk in the DOM.
   TODO: Swap CDK with SortableJS and remove this flag. * */
  @Input() allowHide: boolean; /** Overrides the logic for hidden property and forces hide toggle to appear */
  @Input() marketingOrder: MarketingOrder;

  @Output() readonly updatePhotos = new EventEmitter<any>();
  @Output() readonly selected = new EventEmitter<PhotoViewModel[]>();

  @ViewChildren(PhotoComponent) photoComponents: QueryList<PhotoComponent>;

  displayedViewModels: PhotoViewModel[];
  mediumThumbnailSize = ThumbnailSizes.MEDIUM_THUMBNAIL_WIDTH;
  fallbackThumbnailSize = ThumbnailSizes.LARGE_THUMBNAIL_WIDTH;
  lowResDownloadThumbnailSize = ThumbnailSizes.LARGE_THUMBNAIL_WIDTH;

  aiFeatureIsEnabled$: Observable<boolean>;
  protected readonly AppService = AppService;
  generating = false;
  captionsGenerated: boolean;
  readonly MAX_AI_REQUESTS = 1;

  get thumbnailSize() { return this.useFourWideGrid ? ThumbnailSizes.MEDIUM_THUMBNAIL_WIDTH : ThumbnailSizes.LARGE_THUMBNAIL_WIDTH; }

  constructor(
    private promptDialog: PromptDialogService,
    private photoDownloadService: PhotoDownloadService,
    private galleryService: PhotoGalleryDialogService,
    private launchDarklyService: LaunchDarklyService,
    private orderPhotoService: OrderPhotoService,
    private marketingOrderService: MarketingOrderService,
    private toasterService: ToasterService,
    private readonly store: Store<any>,
  ) {
    this.aiFeatureIsEnabled$ = this.launchDarklyService.getFeature$(FeatureFlags.AI_IMAGE_TAGGING, false);

    this.galleryService.photosUpdated.subscribe((updatedPhotos: ListingPhoto[]) => {
      this.updatePhotos.emit(updatedPhotos);
    });
  }

  ngOnInit(): void {
    if (this.productCode !== null) {
      this.isManagePhoto = false;
      this.gridClassName = 'manage-photo-grid';
    }
    this.captionsGenerated = (this.marketingOrder?.photos || [])
      .some((photo) => Array.isArray(photo.tags) && photo.tags.length > 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewModels || changes.productCode) {
      this.updateDisplay();
    }
  }

  private isReorderable() {
    return (this.productCode === ProductCode.WEBSITE.toString())
      || (this.productCode == null && !this.showFavorites && !!this.numberOrderingEnabled);
  }

  public canDrag() {
    return !this.showFavorites;
  }

  public canDrop() {
    return (!this.productCode || this.photoType != null) && this.photoType !== 'VIDEOEDIT';
  }

  private synchronizeOrderAndGalleryPhotos() {
    const self = this;
    // add new photos from the order to the product
    this.viewModels.forEach((orderVm) => {
      const displayVm = this.displayedViewModels.find((display) => display.photo._id === orderVm.photo._id);
      if (!displayVm) { this.displayedViewModels.push(orderVm); }
    });

    // remove photos from the product that are not in the order
    self.displayedViewModels = self.displayedViewModels.filter((display) => self.viewModels.find((vm) => vm.photo._id === display.photo._id));

    self.displayedViewModels.forEach((display, index) => display.photo.order = index + 1);
  }

  private updateDisplay() {
    if (this.photoType) {
      if (this.photoType === PhotoArrayType.VIDEOEDIT) {
        const productPhotos = this.marketingOrder.getPhotos();
        this.displayedViewModels = productPhotos.map((photo) => new PhotoViewModel(photo));
        this.displayedViewModels.forEach((vm) => this.setDisplayProperties(vm, this.photoType));
      } else {
        const productPhotosMap = this.marketingOrder.getPhotosForProduct(this.productCode);
        let productPhotos = productPhotosMap ? productPhotosMap[this.photoType] : null;
        if (!(productPhotos && productPhotos.length)) {
          productPhotos = this.marketingOrder.getPhotos();
        }
        this.displayedViewModels = productPhotos.map((photo) => new PhotoViewModel(photo));
        if (this.displayedViewModels !== this.viewModels) {
          this.synchronizeOrderAndGalleryPhotos();
        }
        this.displayedViewModels.forEach((vm) => this.setDisplayProperties(vm, this.photoType));
      }
    } else {
      this.updatePhotosSelection(this.displayedViewModels, this.viewModels);
      this.numberOfFavorites = this.viewModels.filter((vm) => vm.photo.favorite).length;
      this.displayedViewModels = this.viewModels
        .filter((vm) => !this.showFavorites || vm.photo.favorite);

      this.viewModels.forEach((vm) => this.setDisplayProperties(vm, ''));
    }
    this.imagesProcessing = false;
  }

  updatePhotosSelection(oldViewModel: PhotoViewModel[], updatedViewModel: PhotoViewModel[]) {
    if (!oldViewModel) return;

    const selectedPhotoIds = oldViewModel.filter((vm) => vm.isSelected).map((vm) => vm.photo._id);

    updatedViewModel.forEach((vm) => {
      if (selectedPhotoIds.indexOf(vm.photo._id) > -1) {
        vm.isSelected = true;
      }
    });
    this.isAllSelected = (updatedViewModel.length > 0) && updatedViewModel.every((display) => display.isSelected);
    this.anySelected = (updatedViewModel.length > 0) && updatedViewModel.some((display) => display.isSelected);
  }

  /**
   * Sets the display properties on the viewModel (i.e. - canDelete, showOrder, etc.)
   * @param vm ViewModel to display
   */
  private setDisplayProperties(vm: PhotoViewModel, photoType) {
    const isProduct = this.productCode != null;

    if (this.photoType === PhotoArrayType.VIDEOEDIT) {
      vm.canReorder = false;
    } else {
      vm.canReorder = this.isReorderable();
    }

    vm.canDelete = !isProduct && !!this.deleteEnabled;
    vm.canDownload = !isProduct && !!this.downloadEnabled;
    vm.canSelect = !isProduct && !!this.selectEnabled;
    vm.canHide = this.allowHide || (this.photoType ? this.photoType === PhotoArrayType.GALLERY : false);
    vm.canFavorite = !isProduct && !!this.favoritesEnabled;
    vm.showFavorite = true;
    vm.showSelected = true;
    vm.useSmallIcons = isProduct;
  }

  onToggleFavorite(viewModel: PhotoViewModel) {
    viewModel.photo.favorite = !viewModel.photo.favorite;
    this.marketingOrderService.updatePhoto(this.marketingOrder._id, viewModel.photo._id, viewModel.photo)
      .catch((error) => {
        viewModel.photo.favorite = !viewModel.photo.favorite;
        const message = error?.error?.error?.message ?? 'An error occured favoriting this photo.';
        this.promptDialog.openPrompt('Error', message, 'Ok');
      });
  }

  onToggleFavorites() {
    this.showFavorites = !this.showFavorites;
    this.select(this.viewModels, false);
    this.updateDisplay();
  }

  onSelect(viewModel: PhotoViewModel) {
    const selected = this.viewModels.filter((vm) => vm.isSelected);
    if (this.viewModels.length === selected.length) {
      this.someSelected = false;
    } else if (selected.length === 0) {
      this.someSelected = false;
    } else if (selected.length > 0) {
      this.someSelected = true;
    }
    this.selected.emit(selected);
    this.updateDisplay();
  }

  onSelectAll() {
    this.select(this.viewModels, this.isAllSelected);
  }

  private select(viewModels: PhotoViewModel[], isSelected: boolean) {
    viewModels.forEach((vm) => vm.isSelected = isSelected);
    this.isAllSelected = this.viewModels.every((display) => display.isSelected);
    this.anySelected = this.viewModels.some((display) => display.isSelected);

    const selected = this.viewModels.filter((vm) => vm.isSelected);
    this.selected.emit(selected);
  }

  /**
   * Creates and returns a vanilla object that looks like a map ( {photoType: ListingPhoto[]} ) in circumstances where
   * the component field 'photoType' is populated.  Otherwise this returns ListingPhoto[].
   */
  private processPhotoResults(viewModels: PhotoViewModel[]): any {
    if (this.photoType) {
      const photos = {};
      photos[this.photoType] = viewModels.map((vm) => vm.photo);
      return photos;
    }
    return viewModels.map((vm) => vm.photo);
  }

  /**
   * Triggered when an array of photos is reordered
   * @param viewModel
   * @param order
   */
  onReorder(viewModel: PhotoViewModel, order: number) {
    if (this.isDragging) { return; } // Don't reorder while dragging

    const newIndex = order - 1; // order is 1 based, index is 0 based
    const currentIndex = this.displayedViewModels.indexOf(viewModel);

    if (this.photoType) {
      moveItemInArray(this.displayedViewModels, currentIndex, newIndex);
      this.updatePhotos.emit(this.processPhotoResults(this.displayedViewModels));
    } else {
      moveItemInArray(this.viewModels, currentIndex, newIndex);
      this.updatePhotos.emit(this.processPhotoResults(this.viewModels));
    }
  }

  /**
   * Triggered when a drag and drop between two photo arrays is executed
   * @param newSort
   */
  onDragAndDrop(newSort: PhotoViewModel[]) {
    this.imagesProcessing = true;
    this.updatePhotos.emit(this.processPhotoResults(newSort));
  }

  onDelete(viewModel: PhotoViewModel) {
    this.delete([viewModel]);
  }

  onDeleteSelected() {
    this.delete(this.viewModels.filter((vm) => vm.isSelected));
  }

  onHide() {
    this.updatePhotos.emit(this.processPhotoResults(this.displayedViewModels));
  }
  /**
   * Open the modal window with the large image and mrk the photo with the given order as the current selected image
   * @param index
   */
  onOpenGallery(index: number) {
    const photos = this.displayedViewModels.map((vm) => vm.photo);
    let title = 'Photo Gallery';
    if (this.marketingOrder && this.marketingOrder.listing) {
      title = this.marketingOrder.listing.formatAddress();
    }
    this.galleryService.open(title, photos, index, this.marketingOrder, this.deleteEnabled, this.favoritesEnabled, this.downloadEnabled);
  }

  private delete(viewModels: PhotoViewModel[]) {
    const multiple = viewModels.length > 1;
    const message = `One or more products may be using ${multiple ? 'these photos' : 'this photo'}. <br> Are you sure you want to delete?`;
    this.promptDialog.openPrompt(UIConstants.CONFIRM, message, UIConstants.YES, [UIConstants.CANCEL])
      .then((action) => {
        if (action?.text !== UIConstants.YES) { return; }

        // Filter out the viewmodels passed in. Those are the remaining photos
        if (this.photoType) {
          let remainingPhotos: ListingPhoto[] = [];
          viewModels.forEach((deleted) => {
            remainingPhotos = this.displayedViewModels.filter((x) => x.photo._id !== deleted.photo._id).map((display) => display.photo);
          });
          const photos = {};
          photos[this.photoType] = remainingPhotos;
          this.updatePhotos.emit(photos);
        } else {
          const remainingPhotos = this.viewModels.filter((x) => viewModels.indexOf(x) < 0).map((display) => display.photo);
          this.updatePhotos.emit(remainingPhotos);
        }
      });
  }

  onDownloadSelected(resolution: 'hi' | 'lo') {
    this.download(this.viewModels.filter((vm) => vm.isSelected), resolution);
  }

  private setWaitingFlags(resolution:string, flag:boolean) {
    if (resolution === 'hi') {
      this.isHiResDwldWaiting = flag;
    } else {
      this.isLowResDwldWaiting = flag;
    }
  }
  private download(viewModels: PhotoViewModel[], resolution: 'hi' | 'lo') {
    const urls = viewModels.map((vm) => this.photoComponents.find((comp) => comp.viewModel === vm))
      .filter((comp) => comp?.presignedDirective != null)
      .map((comp) => {
        return comp.presignedDirective.presignedPhoto.presignedUrl;
      });
    this.setWaitingFlags(resolution, true);
    this.photoDownloadService.getZipURL(urls, resolution).subscribe((response) => {
      setTimeout(async () => {
        try {
          await this.photoDownloadService.downloadZipFile(response.zipFileURL, resolution);
          this.setWaitingFlags(resolution, false);
        } catch (err) {
          this.setWaitingFlags(resolution, false);
        }
      }, 1000);
    }, () => {
      this.setWaitingFlags(resolution, false);
    });
  }

  async generateTagsAndCaptions() {
    const message = 'Favorite up to 10 photos for generating AI captions and key features to enhance your listing description.  This is limited as a \'one use\' option for your listing.';
    const response = await this.promptDialog.openPrompt('Generate Captions and Key Features', message, UIConstants.CONFIRM, [UIConstants.CANCEL]);
    if (response?.text !== UIConstants.CONFIRM) return;

    this.generating = true;
    this.toasterService.showInfo('Generating captions and key features. This may take a few minutes...');
    this.orderPhotoService.generateTagsAndCaptions(this.marketingOrder._id)
      .then(({ photos, ai }) => {
        this.marketingOrder.photos = photos;
        this.marketingOrder.ai = ai;
        this.store.dispatch(UpdateCurrentOrderState({ payload: this.marketingOrder }));
        this.toasterService.showInfo('Photo captions and key features generated');
        this.generating = false;
        this.captionsGenerated = true;
      })
      .catch((error) => {
        this.toasterService.showError(error.message);
        this.generating = false;
      });
  }

  getAiRequests() {
    const role = AppService.isAgentApp ? 'agent' : 'coordinator';
    return this.marketingOrder.ai?.userRequests.find((userRequest) => userRequest.role === role)?.imageCount ?? 0;
  }

  getAiMaxRequests() {
    const role = AppService.isAgentApp ? 'agent' : 'coordinator';
    return this.marketingOrder.ai?.userRequests.find((userRequest) => userRequest.role === role)?.imageMax ?? 1;
  }
}
