// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Third Party Modules
import { NgxFileDropModule } from 'ngx-file-drop';

// LC Modules
import { CoreModule } from '@lc/core';
import {
  InputsModule, DialogsModule, ImagesModule, UICoreModule, SpinnerModule,
} from '@lc/ui';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { SharedComponentsLayoutModule } from './shared-components-layout.module';
import { SharedComponentsUiModule } from './shared-components-ui.module';

import { MultimediaTableComponent } from './media/multimedia-table/multimedia-table.component';
import { MultiMediaItemFormComponent } from './media/multi-media-item-form/multi-media-item-form.component';
import { PropertyPhotoUploadComponent } from './photos/photo-upload/property-photo-upload.component';
import { PhotoComponent } from './photos/photo-list/photo/photo.component';
import { PhotoListComponent } from './photos/photo-list/photo-list.component';
import { ProductPhotosComponent } from './photos/product-photos/product-photos.component';

const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule, MatSliderModule, MatBadgeModule,
  MatIconModule, MatButtonModule, DragDropModule, RouterModule, MatTooltipModule, MatTableModule,
  MatProgressSpinnerModule,
];
const thirdPartyModules = [NgxFileDropModule];
const internalModules = [
  CoreModule, MatProgressBarModule, MatMenuModule, MatDividerModule,
  InputsModule, ImagesModule, UICoreModule, DialogsModule, SharedComponentsUiModule, SharedComponentsLayoutModule,
  SpinnerModule,
];

const mediaComponents = [MultimediaTableComponent, MultiMediaItemFormComponent, PropertyPhotoUploadComponent, PhotoListComponent, ProductPhotosComponent];
const externalComponents = [PhotoComponent];

@NgModule({
  imports: [
    ...angularModules,
    ...thirdPartyModules,
    ...internalModules,
    ...externalComponents,
  ],
  declarations: [
    ...mediaComponents,
  ],
  exports: [
    ...mediaComponents,
  ],
  providers: [],
})
export class SharedComponentsMediaModule {}
