import { Component, Input } from '@angular/core';
import { MultiMediaItemForm } from './multi-media-item.form';

@Component({
  selector: 'lc-multi-media-item-form',
  templateUrl: './multi-media-item-form.component.html',
  styleUrls: ['./multi-media-item-form.component.scss'],
  standalone: false,
})
export class MultiMediaItemFormComponent {
  @Input()
    formGroup: MultiMediaItemForm;

  @Input()
    idPrefix: string = 'add';
}
