<div class="m-4 fx-row x-center">

  <!-- Container for the Photo Order: Centered on page with max width of 1200px -->
  <div *ngIf="photoOrder$ | async as photoOrder" class="fx-column fx-0-1" [style.width]="'1200px'" [style.width]="'1200px'">
    <h4 class="font-weight-bold">Photos & Multimedia</h4>

    <div>
      <span class="font-weight-bold">Order:&nbsp;</span>
      <span>{{photoOrder.photoOrderId}}</span>
    </div>

    <div>
      <span class="font-weight-bold">Address:&nbsp;</span>
      <span *ngIf="photoOrder.propertyAddress">
        {{photoOrder.propertyAddress | address}}
      </span>
    </div>
    <div>
      <span class="font-weight-bold">Agent:&nbsp;</span>
      <span>{{photoOrder.agentName}}</span>
    </div>
    <div>
      <span class="font-weight-bold">Agent Brand:&nbsp;</span>
      <span>{{photoOrder.brandCode}}</span>
    </div>
    <div *ngIf="photoOrder.appointment">
      <span class="font-weight-bold">Appointment:&nbsp;</span>
      <span>{{photoOrder.appointment.scheduledAt | date:'short' : photoOrder.appointment?.timezoneOffset}} {{photoOrder.appointment?.timezone}}</span>
    </div>
    <div *ngIf="photoOrder.package">
      <div >
        <span class="font-weight-bold">Package:&nbsp;</span>
        <span>{{photoOrder.package}}</span>
      </div>
    </div>
    <div>
      <span class="font-weight-bold">Selected Options:&nbsp;</span>
      <span>{{photoOrderSelectedOptions}}</span>
    </div>
    <div *ngIf="photoOrder.appointment && photoOrder.appointment.lockbox">
      <div >
        <span class="font-weight-bold">Code:&nbsp;</span>
        <span>{{photoOrder.appointment.lockbox.code}}</span>
      </div>
      <div  class="appointment-location">
        <span class="font-weight-bold">Location:&nbsp;</span>
        <span>{{photoOrder.appointment.lockbox.location}}</span>
      </div>
    </div>

    <div *ngIf="photoOrder.appointment">
      <span class="font-weight-bold">Comments:&nbsp;</span>
      <span>{{photoOrder.appointment.comments}}</span>
    </div>

    <lc-navigation-tabs [tabs]="tabs" [activeId]="activeId$ | async"></lc-navigation-tabs>
  </div>
</div>
