// Angular Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import {
  AuthGuard,
  CoreModule,
  StateModule,
  AppService,
  initializeApp,
  StartupService,
  LaunchDarklyService,
  DataDogLogger,
  CloseDialogResolve,
} from '@lc/core';
import { LayoutWithoutSidenavComponent, SharedComponentsLayoutModule } from '@lc/shared-components-layout';
import { NgxFileDropModule } from 'ngx-file-drop';
import {
  InactivityLogoutComponent, SharedComponentsAuthModule, LogoutComponent, OktaCallbackComponent, OktaLoginComponent,
} from '@lc/shared-components-auth';
import { SharedComponentsMediaModule } from '@lc/shared-components-media';
import { SharedComponentsUiModule } from '@lc/shared-components-ui';
import { UserIdleModule, UserIdleConfig } from 'angular-user-idle';
import { InputsModule, OrderFiltersModule, UITabsModule } from '@lc/ui';
import { ImpersonatePhotographerComponent } from './impersonate-photographer/impersonate-photographer.component';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { ManagePhotosComponent } from './manage-photos/manage-photos.component';
import { ManageMediaComponent } from './manage-media/manage-media.component';
import { ManageMultimediaComponent } from './manage-media/manage-multimedia/manage-multimedia.component';
import { AppComponent } from './app.component';

const angularModules = [
  BrowserModule, FormsModule, ReactiveFormsModule, MatInputModule,
  BrowserAnimationsModule, MatButtonModule, MatTableModule, MatBadgeModule, MatProgressSpinnerModule,
  MatFormFieldModule, MatIconModule, MatMenuModule, MatSliderModule, MatStepperModule, MatButtonToggleModule, MatTooltipModule,
];

if (location.hostname !== 'localhost' && location.hostname !== 'remotedev') {
  DataDogLogger.initialize('pub2f4270a57482b318c01ddb4aba9c57d9');
  DataDogLogger.initializeDDRealUserMonitoring('pub2f4270a57482b318c01ddb4aba9c57d9');
}

@NgModule({
  declarations: [
    AppComponent,
    ImpersonatePhotographerComponent,
    AppointmentListComponent,
    ManagePhotosComponent,
    ManageMediaComponent,
    ManageMultimediaComponent,
  ],
  imports: [
    ...angularModules,

    CoreModule.forRoot(),
    StateModule,
    NgxFileDropModule,
    InputsModule,
    UITabsModule,
    OrderFiltersModule,
    SharedComponentsUiModule.forRoot(),
    SharedComponentsAuthModule,
    SharedComponentsLayoutModule,
    SharedComponentsMediaModule,

    RouterModule.forRoot([
      {
        // NOTE: These routes require AUTHENTICATION
        path: '',
        component: LayoutWithoutSidenavComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          { path: '', component: ImpersonatePhotographerComponent },
          { path: 'appointment-list/:photographerId', component: AppointmentListComponent },
          {
            path: 'manage-media/:photographerId/:photoOrderId',
            component: ManageMediaComponent,
            children: [
              { path: '', redirectTo: 'photos', pathMatch: 'full' },
              { path: 'photos', component: ManagePhotosComponent, data: { activeId: 'photos' } },
              { path: 'multimedia', component: ManageMultimediaComponent, data: { activeId: 'multimedia' } },
            ],
          },
          { path: 'pages', loadChildren: () => import('@lc/ui').then((m) => m.UiModule) },

        ],
      },
      {
        // NOTE: These are the UNAUTHENTICATED routes that do not require authentication.
        // These routes do not use the LayoutWithoutSidenav due to the sidenav making
        // API requests. We only need an empty container anyways, as the toolbar never
        // shows for the logged out routes
        path: '',
        resolve: { closeDialogs: CloseDialogResolve },
        children: [
          { path: 'okta/login', component: OktaLoginComponent },
          { path: 'login/callback', component: OktaCallbackComponent },
          { path: 'implicit/callback', component: OktaCallbackComponent },
          { path: 'inactivity-logout', component: InactivityLogoutComponent },
          { path: 'logout', component: LogoutComponent },
        ],
      },
    ], {
      enableTracing: false,
      paramsInheritanceStrategy: 'always', // This is required to allow nested modules to retrieve all query parameters (i.e.: 'id')
      scrollPositionRestoration: 'enabled',
    }),
    UserIdleModule,

  ],
  providers: [
    // User activity timeouts
    // Ping value is not used
    // Session timeout is pulled from AppSettings after load.  If not found default is 5 minutes
    { provide: UserIdleConfig, useFactory: () => ({ idle: AppService.get('sessionTimeout') * 60, timeout: 30, ping: 10000 }) },
    provideAppInitializer(() => {
      const initializerFn = (initializeApp)(inject(StartupService));
      return initializerFn();
    }),
    LaunchDarklyService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
