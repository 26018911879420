import {
  Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Dimension,
  Dimensions,
  ListingPhoto,
  MarketingOrder, PhotoArrayType,
  PhotoThumbnail,
  PhotoThumbnails,
  ProductCategory,
  ProductInstance,
  ProductKind,
  TemplateInstanceMetaData,
  TemplateService,
  ThumbnailSizes,
  ProductCode,
} from '@lc/core';
import { PhotoCropSettings, ImageCropperNewDialogService, CropRequest } from '@lc/ui';
import { PhotoViewModel } from '../photo-view-model';
import { PhotoGalleryDialogService } from '../../dialogs/photo-gallery-dialog/photo-gallery-dialog.service';

@Component({
  selector: 'lc-product-photos',
  templateUrl: './product-photos.component.html',
  styleUrls: ['./product-photos.component.scss'],
  standalone: false,
})
export class ProductPhotosComponent implements OnChanges {
  mediumThumbnailSize = ThumbnailSizes.MEDIUM_THUMBNAIL_WIDTH;

  @Input()
    productCode: string;

  @Input()
    marketingOrder: MarketingOrder;

  @Input()
    viewModels: PhotoViewModel[];

  @Input()
    photoType: string;

  @Output()
  readonly updatePhotos = new EventEmitter<any>();

  /** scrollable is used for scrolling the UI left and right through the pages and photos */
  @ViewChild('scrollable', { read: ElementRef })
  public scrollable: ElementRef<any>;

  @ViewChild(CdkDropList)
  public drop: CdkDropList;

  productPhotos: PhotoViewModel[];
  product: ProductInstance;
  isWebsite = false;
  isVideoService = false;

  templateInfo: TemplateInstanceMetaData;

  constructor(
    private cropDialog: ImageCropperNewDialogService,
    private templateInfoService: TemplateService,
    private galleryService: PhotoGalleryDialogService,
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.productCode) {
      this.product = this.marketingOrder.getProduct(this.productCode);
      this.isWebsite = this.isWebsiteProduct(this.product);
      this.isVideoService = this.product?.kind === ProductKind.SERVICE && this.product?.category === ProductCategory.VIDEO;
      await this.initializeProductPhotos();
    }
    if (changes.viewModels && this.productPhotos) {
      this.updateInUseIndex();
    }
  }

  /**
   * Initializes the Product Photos by:
   * 1.) Grabbing photos off of existing product template
   * 2.) Padding it with photos from the marketing order until count matches template's PhotoCount
   * 3.) Padding it with empty placeholder photos
   */
  private async initializeProductPhotos() {
    if (!this.isVideoService) {
      this.templateInfo = this.product?.selectedTemplate?.templateInfo;
      if (!this.templateInfo) {
        return;
      }
    }

    // Get the photos in use on the marketing order
    const productPhotosMap = this.marketingOrder.getPhotosForProduct(this.productCode);
    let productPhotos = productPhotosMap ? productPhotosMap[this.photoType] : null;
    if (!(productPhotos && productPhotos?.length)) {
      // No existing photos so use the default behavior from the marketing order photos
      productPhotos = this.defaultSort(this.marketingOrder.photos);
    }

    // Pad product photos until contains enough images
    const productPhotoViewModels: PhotoViewModel[] = this.padProductPhotos(productPhotos);

    productPhotoViewModels.forEach((vm, index) => this.setDisplayProperties(vm, index + 1));
    this.productPhotos = productPhotoViewModels.slice(0, this.isVideoService ? this.product.details.maxPhotos : this.templateInfo.photoCount);
    this.updateInUseIndex();
  }

  /**
   * Sets the display properties on the viewModel (i.e. - canDelete, showOrder, etc.)
   * @param viewModel ViewModel to display
   * @param order the order of the viewModel in the collection (1 based index)
   */
  private setDisplayProperties(viewModel: PhotoViewModel, order: number) {
    const isDefault = viewModel.isDefault();
    viewModel.showOrder = true;
    viewModel.showFavorite = true;
    viewModel.useSmallIcons = true;
    viewModel.showSelected = false;
    viewModel.canDelete = !isDefault;
    viewModel.canEdit = !isDefault && (this.photoType ? this.photoType !== PhotoArrayType.VIDEO : true);
    viewModel.disablePortrait = this.product?.isVideoService();
    viewModel.iconPlacement = 'center';
    viewModel.photo.order = order;
    viewModel.photo.hidden = false;

    // Only set the pageDisplay if the page order changed
    let photoCount = 0;
    let currentPageNumber = 0;
    let previousPageNumber = 0;
    if (this.templateInfo && this.templateInfo.photoInfo) {
      photoCount = this.templateInfo.photoInfo.length;
      currentPageNumber = order > 0 && order <= photoCount ? this.templateInfo.photoInfo[order - 1].pageNumber : 0;
      previousPageNumber = order > 1 && order <= photoCount ? this.templateInfo.photoInfo[order - 2].pageNumber : 0;
    }
    viewModel.pageDisplay = currentPageNumber > previousPageNumber ? `Page ${currentPageNumber}` : null;
  }

  private updateInUseIndex() {
    // Locate the photos in the main list of all photos and mark in-use
    this.productPhotos.map((productVm, index) => {
      // Update in use on the shared viewModels
      // Note: This same array is in use on other views
      const moVm = this.viewModels.find((vm) => vm.photo._id === productVm.photo._id);
      if (moVm) {
        moVm.inUseIndex = index;
      }
    });
  }
  /**
   * Open the modal window with the large image and mrk the photo with the given order as the current selected image
   * @param index
   */
  onOpenGallery(index) {
    const photos = this.productPhotos.map((vm) => vm.photo);
    this.galleryService.open(this.marketingOrder.listing.formatAddress(), photos, index, this.marketingOrder);
  }

  /**
   * Handles the drag and drop event
   * @param event The Angular CDK DragDropEvent
   */
  async onDragAndDrop(event: CdkDragDrop<PhotoViewModel[]>) {
    if (event.previousContainer === event.container) {
      if (event.previousIndex === event.currentIndex) { return; } // no need to do any work if the source and destination are the same

      // Moving Photos within the existing array
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const newPhoto = event.previousContainer.data[event.previousIndex];
      const replacedViewModel = event.container.data[event.currentIndex];

      // If moving on an index of a default image, replace it.
      this.replacePhoto(replacedViewModel, new PhotoViewModel(new ListingPhoto(newPhoto.photo)));
    }

    // Update Photos on the ProductTemplate
    this.executeUpdate();
  }

  /**
   * Scrolls the UI left or right
   *
   * @param direction
   */
  onScroll(direction: 'left' | 'right'): void {
    const nativeElement = this.scrollable?.nativeElement;
    if (!nativeElement) { return; }
    // scroll by a percentage of the width of the element so it's consistent regardless of viewport size
    const width = nativeElement.offsetWidth * 0.5;
    const scrollAmount = direction === 'right' ? (width) : -(width);
    nativeElement.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }

  /**
   * Opens up the photo cropper dialog with the current image's aspect ratio
   * @param vm Photo to crop
   */
  async onCropPhoto(vm: PhotoViewModel, photoIndex: number) {
    if (this.templateInfo?.photoInfo) {
      /**
       * we pick crop Image width and height from templateSpec photoInfo
       * using photoIndex
       */
      const metadata = this.templateInfo.photoInfo[photoIndex];
      const templateDimension = new Dimension(metadata.width, metadata.height);

      vm.photo.dimensions = vm.photo?.dimensions || {};
      const photoDimension = vm.photo.dimensions[vm.photo.order] || new Dimensions();
      photoDimension.templateDimensions = templateDimension;

      // Set the crop settings for the given photo
      const cropSettings = new PhotoCropSettings(metadata.width / metadata.height);
      cropSettings.dimensions = photoDimension;
      const response = await this.cropDialog.openImageCropper(new CropRequest(vm.photo.uri, `Edit Photo ${vm?.photo?.order}`, cropSettings));

      if (response?.croppedPhotos?.length) {
        // Store dimensions and execute update
        const dimensions = response.croppedPhotos[0]?.dimensions || {};
        dimensions.templateDimensions = photoDimension.templateDimensions;
        vm.photo.dimensions[vm.photo.order] = dimensions;
        this.executeUpdate();
      }
    }
  }

  /**
   * Deletes a photo from the ProductTemplate and replaces it with a placeholder image
   * @param vm Photo to delete
   */
  async onDeletePhoto(vm: PhotoViewModel) {
    vm.inUseIndex = null;
    this.replacePhoto(vm, this.buildPlaceholderImage(vm.photo.order - 1));
    this.executeUpdate();
  }

  /**
   * Occurs when the image loads. If the image is found to be disabled because of portrait mode,
   * remove it from the list
   * @param vm
   */
  onPortraitDisabled(disabledPhoto: PhotoViewModel) {
    this.onDeletePhoto(disabledPhoto);
  }

  /**
   * Replaces a photo in ProductTemplate and emits an UpdateEvent
   */
  private async replacePhoto(oldPhoto: PhotoViewModel, newPhoto: PhotoViewModel) {
    const indexOfPhoto = this.productPhotos.indexOf(oldPhoto);
    this.productPhotos.splice(indexOfPhoto, 1, newPhoto);
  }

  /**
   * Updates the current template product's photos and emits the UpdateEvent
   */
  private executeUpdate() {
    // If array exceeds max length, drop photos off the end
    this.productPhotos = this.productPhotos.splice(0, this.templateInfo ? this.templateInfo.photoCount : this.product.details.maxPhotos);
    this.productPhotos.forEach((vm, index) => this.setDisplayProperties(vm, index + 1));

    // create a map looking object to save back to the product
    const productPhotosMap = {};
    productPhotosMap[this.photoType] = this.productPhotos.map((vm) => vm.photo);
    this.updatePhotos.emit(productPhotosMap);
    this.updateInUseIndex();
  }

  /**
   * Pads the product photos with enought default images to fill the pages
   * @param productPhotos Current photos array
   */
  private padProductPhotos(productPhotos: ListingPhoto[]) {
    const viewModels = productPhotos.map((photo) => new PhotoViewModel(photo));
    if (this.templateInfo) {
      while (viewModels.length < this.templateInfo.photoCount) {
        const index = viewModels.length - 1;
        const photo = this.buildPlaceholderImage(index);
        viewModels.push(photo);
      }
    } else {
      while (viewModels.length < this.product.details.maxPhotos) {
        const index = viewModels.length - 1;
        const photo = this.buildPlaceholderImage(index);
        viewModels.push(photo);
      }
    }

    return viewModels;
  }

  // Sorts the photos by favorite and order first and then non-favorites and order second
  private defaultSort(photos: ListingPhoto[]) {
    return photos.filter((photo) => photo.favorite)
      .sort((photo1, photo2) => photo1.order - photo2.order)
      .concat(photos.filter((photo) => !photo.favorite)
        .sort((photo1, photo2) => photo1.order - photo2.order));
  }

  // builds a placeholder image for the in-use photo array.  Currently required because the print renderer cannot handle a
  // partially populated array of photos.
  private buildPlaceholderImage(index: number): PhotoViewModel {
    const placeholderUri = 'assets/images/welcome-house.png';
    const placeholder = new ListingPhoto();
    placeholder.favorite = false;
    placeholder.uri = placeholderUri;
    placeholder.order = index + 1;
    placeholder.uploadedBy = 'placeholder';

    placeholder.thumbnails = new PhotoThumbnails();
    placeholder.thumbnails[ThumbnailSizes.MEDIUM_THUMBNAIL_WIDTH] = new PhotoThumbnail({ uri: placeholderUri });
    placeholder.thumbnails[ThumbnailSizes.LARGE_THUMBNAIL_WIDTH] = new PhotoThumbnail({ uri: placeholderUri });

    const viewModel = new PhotoViewModel(placeholder);
    this.setDisplayProperties(viewModel, index + 1);
    return viewModel;
  }

  isWebsiteProduct(product: ProductInstance) {
    // TODO - not the best test but in place to change 'Apply Changes' text to 'Publish' for two different UIs
    return product?.code === ProductCode.WEBSITE;
  }
}
