// tslint:disable: no-output-on-prefix
import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
  Attribute,
} from '@angular/core';
import { MultiMediaItem } from '@lc/core';
import { MatDialog } from '@angular/material/dialog';
import { MultiMediaItemForm } from '../multi-media-item-form/multi-media-item.form';

class MultimediaViewModel {
  constructor(public item: MultiMediaItem, public isSelected: boolean = false) {
  }
}

@Component({
  selector: 'lc-multimedia-table',
  templateUrl: './multimedia-table.component.html',
  styleUrls: ['./multimedia-table.component.scss'],
  standalone: false,
})
export class MultimediaTableComponent implements OnChanges {
  readonly rows: string[];

  @Input()
    showHeaders: boolean;

  @Input()
    name = 'selectedMM';

  @Input()
    type = 'video';

  @Input()
    items: MultiMediaItem[];

  @Input()
    selectedItems: MultiMediaItem[] = [];

  @Output()
  readonly onUpdate = new EventEmitter<MultiMediaItem>();

  @Output()
  readonly onDelete = new EventEmitter<MultiMediaItem[]>();

  @Output()
  readonly onSelection = new EventEmitter<MultiMediaItem[]>();

  @Output()
  readonly onChange = new EventEmitter<MultiMediaItem[]>();

  formGroup = new MultiMediaItemForm();

  editItem: MultiMediaItem;

  deleteEnabled = true;
  selectEnabled = true;
  editEnabled = true;
  isProduct = false;
  multiselect = true;

  viewModels: MultimediaViewModel[] = [];
  allSelected = false;
  noneSelected = true;

  constructor(
  @Attribute('deleteEnabled') deleteEnabled : string,
    @Attribute('selectEnabled') selectEnabled : string,
    @Attribute('editEnabled') editEnabled : string,
    @Attribute('isProduct') isProduct: string,
    @Attribute('multiselect') multiselect: string,
    private matDialog: MatDialog,
  ) {
    this.deleteEnabled = (deleteEnabled === null) || (deleteEnabled === 'true');
    this.selectEnabled = (selectEnabled === null) || (selectEnabled === 'true');
    this.editEnabled = (editEnabled === null) || (editEnabled === 'true');
    this.isProduct = isProduct && (isProduct === 'true');
    this.multiselect = (multiselect === null) || (multiselect === 'true');
    this.rows = [
      ...(this.selectEnabled ? ['select'] : []),
      'name', 'link', 'date', 'agent',
      ...(this.editEnabled || this.deleteEnabled ? ['actions'] : []),
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && this.items != null) {
      // Query the items, sort by date descending and map into an appropriate view model
      this.viewModels = this.items
        .sort((a, b) => b.audit.updatedAt.getTime() - a.audit.updatedAt.getTime())
        .map((item) => new MultimediaViewModel(
          item,
          this.selectedItems.filter((mmedia) => mmedia.title === item.title && mmedia.uri === item.uri).length > 0,
        ));

      if (!this.isProduct && !changes.items.firstChange) {
        this.onSelectionChanged();
      }
    }
    if (changes.selectedItems) {
      // when selections change, fix the selections on the view models which is the wrapper on items
      changes.selectedItems.currentValue.forEach((item) => {
        const match = this.viewModels.find((vm) => vm.item._id === item._id);
        if (match) {
          match.isSelected = true;
        }
      });
    }
  }

  /**
   * Queries the selected ViewModels and sets the appropriate properties needed for the UI
   * to determine the status of the Check All checkbox and Delete Selected button
   * @param selectedIndex 0-n for the selection.
   */
  onSelectionChanged(selectedIndex?: number) {
    this.allSelected = this.viewModels.every((vm) => vm.isSelected);
    this.noneSelected = this.viewModels.every((vm) => !vm.isSelected);
    if (!this.multiselect && selectedIndex >= 0) {
      this.viewModels.forEach((vm, index) => {
        vm.isSelected = index === selectedIndex;
      });
    }
    this.selectedItems = this.viewModels.filter((vm) => vm.isSelected).map((vm) => vm.item);
    this.onSelection.emit(this.selectedItems);
  }

  /**
   * Queries the selected ViewModels and sets the appropriate properties needed for the UI
   * to determine the status of the Check All checkbox and Delete Selected button
   * @param selectedIndex 0-n for the selection.
   */
  onMouseClick(selectedIndex?: number) {
    this.allSelected = this.viewModels.every((vm) => vm.isSelected);
    this.noneSelected = this.viewModels.every((vm) => !vm.isSelected);
    if (!this.multiselect && selectedIndex >= 0) {
      this.viewModels.forEach((vm, index) => {
        if (vm.isSelected && index === selectedIndex) {
          vm.isSelected = false;
        } else {
          vm.isSelected = index === selectedIndex;
        }
      });
    }
    this.selectedItems = this.viewModels.filter((vm) => vm.isSelected).map((vm) => vm.item);
    this.onChange.emit(this.selectedItems);
  }
  /**
   * Toggles the status of all of the multimedia links. If any of the links are selected, toggle
   * will deselect all links. If none are selected, it will select all the links.
   */
  toggleAll() {
    const checkAll = this.viewModels.every((vm) => vm.isSelected) || this.viewModels.every((vm) => !vm.isSelected);
    if (checkAll || this.allSelected) {
      this.viewModels.forEach((vm) => vm.isSelected = this.allSelected);
      this.onSelectionChanged();
    }
  }

  /**
   * Queries all selected ViewModels and will select the appropriate link. It will then emit the
   * onDelete event with the selected multimedia links
   */
  deleteSelected() {
    const selectedItems = this.viewModels.filter((vm) => vm.isSelected).map((vm) => vm.item);
    this.onDelete.emit(selectedItems);
  }

  open(modal, item) {
    this.editItem = item;
    this.formGroup.patchValue(item);
    this.matDialog.open(modal, { width: '500px', maxWidth: '90vw', maxHeight: '90vh' });
  }

  doUpdate() {
    Object.assign(this.editItem, this.formGroup.value);

    this.onUpdate.emit(this.editItem);
    this.close();
  }

  onCancel() {
    this.close();
  }

  close() {
    this.matDialog.closeAll();
    this.editItem = null;
    this.formGroup.reset();
  }
}
