import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  LOGGEDINUSER, UserAgentService, User, AppInitializerService, AppService, GTagService, NavigationHelper,
} from '@lc/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'lc-photo-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  private readonly GTAG_MAP = new Map([['prod', 'GTM-MXFDPKC']]);

  constructor(
    store: Store<any>,
    userAgentService: UserAgentService,
    private route: ActivatedRoute,
    public router: Router,
    private gtagService: GTagService,
    private appInitializer: AppInitializerService,
  ) {
    userAgentService.doBrowserCheck();
    appInitializer.initializeKeys('lc-photo-app', AppService.productSuiteName);

    const user$: Observable<User> = store.select(LOGGEDINUSER).pipe(shareReplay(1));
    NavigationHelper.urlChanged$(router).pipe(
      switchMap((url) => user$.pipe(map((user) => ({ user, url })))),
    ).subscribe(({ user, url }) => {
      // Update the google tag service
      this.gtagService.updateGTag(this.GTAG_MAP, user, url, 'Photographer');

      // Refresh Notifications
      const component = this.route.snapshot.firstChild.firstChild;
      const photographerId = component.paramMap.get('photographerId');
      this.appInitializer.refreshNotifications(url, photographerId ? [photographerId] : []);
    }, (error) => { throw new Error(error); });
  }
}
