import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderFiltersForm, StatusOption } from '@lc/ui';
import {
  PhotoOrder, PhotoOrderService, UIConstants, PHOTOGRAPHY_AGENCIES, PhotoAgency, GetAllPhotographyAgencies,
  MarketingOrdersAreasService, ToasterService, PhotoOrderStatus, PromptDialogService,
} from '@lc/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  debounceTime, filter, map, startWith, switchMap, tap,
} from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { PageRequestForm } from '../models/page-request.model';

/**
 * This ViewModel is the main view model for this UI.
 * It contains the agency information as well as the
 * viewModels used for each row in the table.
 */
export class ViewModel {
  readonly agencyName: string;
  readonly hasMultipleAgencies: boolean;
  readonly photoOrderViewModels: PhotoOrderViewModel[];

  constructor(readonly photoOrders: PhotoOrder[], selectedAgencyId: string, agencies: PhotoAgency[]) {
    const selectedAgency = (agencies || []).find((agency) => agency._id === selectedAgencyId);
    this.agencyName = selectedAgency?.title;
    this.hasMultipleAgencies = (agencies || []).length > 1;
    this.photoOrderViewModels = (photoOrders || []).map((photoOrder) => new PhotoOrderViewModel(photoOrder));
  }
}

/**
 * This ViewModel is used by each record in the table and signifies
 * the actions that can be performed on each photoOrder.
 */
export class PhotoOrderViewModel {
  readonly canClick: boolean;
  readonly canStart: boolean;
  readonly canCancel: boolean;
  readonly canComplete: boolean;
  readonly hasActions: boolean;

  constructor(readonly photoOrder: PhotoOrder) {
    this.canClick = photoOrder.status !== PhotoOrderStatus.CANCELLED;
    this.canStart = photoOrder.status === PhotoOrderStatus.OPEN;
    this.canCancel = photoOrder.status !== PhotoOrderStatus.CANCELLED;
    this.canComplete = photoOrder.status === PhotoOrderStatus.SUBMITTING;
    this.hasActions = this.canStart || this.canCancel || this.canComplete;
  }
}

@Component({
  selector: 'lc-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.scss'],
  standalone: false,
})
export class AppointmentListComponent {
  /** Whether or not the UI is loading and should display the indicator */
  isLoading: boolean;
  totalCount: number;

  /** The areas that are available on the filters */
  readonly areas$: Observable<string[]>;

  readonly pageRequestForm = new PageRequestForm();

  /** The main viewModel used to represent the UI */
  readonly viewModel$: Observable<ViewModel>;

  /** The columns that display in the table */
  readonly columns = ['order-number', 'order-date', 'agent', 'scheduled-date', 'address', 'status', 'action'];

  /** The form used by the filters */
  readonly filtersForm = new OrderFiltersForm({
    statusOptions: [
      new StatusOption('To Do', '#E1E8ED', [PhotoOrderStatus.OPEN]),
      new StatusOption('In Progress', '#F4B124', [PhotoOrderStatus.SERVICING]),
      new StatusOption('Submitting', '#3989C9', [PhotoOrderStatus.SUBMITTING]),
      new StatusOption('Done', '#657786', [PhotoOrderStatus.CLOSED]),
    ],
  });

  /** Triggers a manual reload of the photos */
  private readonly reload$ = new BehaviorSubject<true>(true);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: PromptDialogService,
    private store: Store<any>,
    private photoOrderService: PhotoOrderService,
    areaService: MarketingOrdersAreasService,
    private toasterService: ToasterService,
  ) {
    const pageChanges$ = this.pageRequestForm.valueChanges.pipe(
      startWith(this.pageRequestForm.value),
      debounceTime(200),
      map(() => this.pageRequestForm.value),
    );

    this.areas$ = areaService.getAreas$();
    this.filtersForm.userFilters = 'agent';

    // Subscribe to route changes
    const queryParams$ = this.route.queryParams.pipe(map((params) => this.parseQueryParams(params)));
    const photographerId$: Observable<string> = this.route.params.pipe(map((params) => params.photographerId));

    // Subscribe to the agencies in the store
    const agencies$ = this.store.select(PHOTOGRAPHY_AGENCIES).pipe(
      tap((agencies: PhotoAgency[]) => {
        if (agencies == null) {
          // If agencies is null, tell the store to load agencies
          this.store.dispatch(GetAllPhotographyAgencies({ payload: null }));
        }
      }),

      // Only emit once we have agencies
      filter((agencies) => agencies != null),
    );

    // Set defaults of filter form prior to passing in params to photoOrderService
    this.setDefaultPreferences();

    // Subscribe to the reload behavior subject, which emits immediately upon entering page and anytime there are updates
    this.viewModel$ = combineLatest([photographerId$, pageChanges$, queryParams$, this.reload$]).pipe(
      // Mark reloading when any of the following triggers a change
      tap(() => this.isLoading = true),

      // Get the photo agencies
      switchMap(([photographerId, pageRequest]) => agencies$.pipe(map((agencies) => ({ agencies, photographerId, pageRequest })))),

      // Combine the agencies and photographer id to get the photo orders
      switchMap(({ agencies, photographerId, pageRequest }) => {
        const params = this.filtersForm.getFilters();
        return this.photoOrderService.retrievePhotoOrders(photographerId, params, pageRequest).pipe(
          map((response) => {
            this.pageRequestForm.totalCount = response.totalCount;
            return new ViewModel(response.orders, photographerId, agencies);
          }),
        );
      }),
      tap(() => this.updateQueryParams()),
      tap(() => this.isLoading = false),
    );
  }

  onFilter() {
    this.reload$.next(true);
  }

  // Sets the default values for filters form
  private setDefaultPreferences() {
    // reset filter form
    this.filtersForm.reset();

    // Default the date to the last 30 days
    const customDateOption = this.filtersForm.dateOptions.find((dateOption) => dateOption.text === 'Custom');
    const now = new Date();
    customDateOption.startDate ??= new Date(now.setDate(now.getDate() - 30));
    customDateOption.endDate ??= new Date();
    this.filtersForm.selectedDateOption = customDateOption;

    // Set default initial status to TO DO ( OPEN ), IN PROGRESS ( SERVICING ), SUBMITTING
    this.filtersForm.setByStatus([PhotoOrderStatus.OPEN, PhotoOrderStatus.SERVICING, PhotoOrderStatus.SUBMITTING]);
  }

  async onClick(photoOrderVm: PhotoOrderViewModel) {
    if (photoOrderVm.canStart) {
      return await this.start(photoOrderVm.photoOrder);
    } if (photoOrderVm.canClick) {
      return this.navigateToOrder(photoOrderVm.photoOrder);
    }
  }

  /** Prompts the user and then proceeds to start the order */
  async start(photoOrder: PhotoOrder) {
    // Prompt user to confirm
    const message = 'This confirms you are accepting this order and will confirm the preferred date/time with the Agent. Do you want to continue?';
    const response = await this.dialogService.openPrompt(UIConstants.CONFIRM, message, UIConstants.YES, [UIConstants.NO]);
    if (response?.text !== UIConstants.YES) { return; } // Don't do anything if they did not click yes

    // Make a call to start order
    await this.photoOrderService.startOrder(photoOrder)
      .then(() => this.navigateToOrder(photoOrder))
      .catch(() => this.toasterService.showError('Failed to start order'));
  }

  /** Prompt the user and then proceeds to complete the order */
  async complete(photoOrder: PhotoOrder) {
    // Prompt user to confirm
    const message = 'This confirms you are completing this order. Do you want to continue?';
    const response = await this.dialogService.openPrompt(UIConstants.CONFIRM, message, UIConstants.YES, [UIConstants.NO]);
    if (response?.text !== UIConstants.YES) { return; } // Don't do anything if they did not click yes

    // Make a call to complete order
    await this.photoOrderService.completeOrder(photoOrder)
      .then(() => this.reload$.next(true))
      .catch(() => this.toasterService.showError('Failed to complete order'));
  }

  /** Prompts user and then proceeds to cancel the order */
  async cancel(photoOrder: PhotoOrder) {
    // Prompt user to confirm
    const message = 'Are you sure you want to cancel this photography order?';
    const response = await this.dialogService.openPrompt(UIConstants.CONFIRM, message, UIConstants.YES, [UIConstants.NO]);
    if (response?.text !== UIConstants.YES) { return; } // Don't do anything if they did not click yes

    // Make a call to complete order
    await this.photoOrderService.cancelOrder(photoOrder)
      .then(() => this.reload$.next(true))
      .catch(() => this.toasterService.showError('Failed to cancel order'));
  }

  /** Navigates to the specified photo order */
  private navigateToOrder(photoOrder: PhotoOrder) {
    this.router.navigate(['/manage-media', photoOrder.photoAgencyId, photoOrder._id]);
  }

  /** Parses the query params from the url and patches the form values */
  private parseQueryParams(params) {
    this.filtersForm.patchFromQueryParams(params);
  }

  /** Updates the query parameters in the current url */
  private updateQueryParams() {
    const params = this.filtersForm.getQueryParams();
    this.router.navigate([], { relativeTo: this.route, queryParams: params });
  }

  onPageChanged(event: PageEvent) {
    this.pageRequestForm.pageSize.setValue(event.pageSize);
    this.pageRequestForm.pageNumber.setValue(event.pageIndex + 1);
  }
}
