import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Media, MultiMediaItem, PhotoOrder, PhotoOrderService,
  MultiMediaItemRequest,
} from '@lc/core';
import { map, switchMap } from 'rxjs/operators';
import { MultiMediaItemForm } from '@lc/shared-components-media';
import { ActivatedRoute } from '@angular/router';
import { FormGroupDirective } from '@angular/forms';

class ViewModel {
  readonly multimediaLinks: MultiMediaItem[];

  constructor(readonly photoOrder: PhotoOrder, readonly multimedia: Media) {
    this.multimediaLinks = multimedia.multimedia || [];
  }
}

@Component({
  selector: 'lc-manage-multimedia',
  templateUrl: './manage-multimedia.component.html',
  standalone: false,
})
export class ManageMultimediaComponent {
  addFormGroup = new MultiMediaItemForm();

  // Angular FormDirective needs to be reset after submission so that the error validations are removed.
  // See: https://github.com/angular/components/issues/4190
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  readonly viewModel$: Observable<ViewModel>;

  constructor(
    private route: ActivatedRoute,
    private photoOrderService: PhotoOrderService,
  ) {
    // Query the photo order
    this.viewModel$ = this.route.params.pipe(
      map((params) => params.photoOrderId),
      switchMap((orderId) => this.photoOrderService.getOrder$(orderId)),
      switchMap((order) => this.photoOrderService.getMedia$(order).pipe(
        map((media) => new ViewModel(order, media)),
      )),
    );
  }

  async addLink(order: PhotoOrder) {
    // prepare add link request
    const request = new MultiMediaItemRequest(this.addFormGroup.value);
    request.createdById = order.photoAgencyId;

    await this.photoOrderService.addMultimediaLink(order, request);

    this.addFormGroup.reset();
    this.formDirective.resetForm();
  }
}
